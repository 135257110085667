import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API/index.js';
import { useAuth } from '../../utils/Auth';
import { useKeyGen } from '../../utils/keygen/index.js';
import { useHandleTimezone } from '../../utils/hooks/useHandleTimezone.js';
import { useCheckEmail } from '../../utils/hooks/useCheckEmail.js';
import { useQuery } from '../../utils/hooks/useQuery.js';
import logo from '../../components/Images/LiquidQR-dark.png';
import QRList from '../../components/QRSwitch/QRList/index.js';
import styles from './Authenticate.module.css';

const Authenticate = () => {
    const { currentUser, create, login, logout, userObj, orgObj, liquidQRs } = useAuth();
    const { addUIDtoDB, createLiquidQR, updateUserObject, deactivateQR } = API;
    const [ user, setUser ] = useState({ qrId: '', uid: currentUser?.uid ?? '', email: '', password: '', type: currentUser?.uid ? 'login' : 'new' });
    const [ errObj, setErrObj ] = useState({ error: false, message: '', loading: false });
    const [ selectedQr, setSelectedQr ] = useState({ obj: { id: '', data: {} }, selected: false });
    const bsUid = useQuery().get('bsUid');
    const formattedTimezone = useHandleTimezone();
    const emailCheck = useCheckEmail(user);
    const qrId = useKeyGen();
    const bsQrs = useMemo(() => liquidQRs?.filter(obj => obj.data.active === true && obj.data.qr_type === 'bitsignal'), [liquidQRs]);
    const lqrLength = useMemo(() => liquidQRs?.length ? liquidQRs.length : 0,[liquidQRs]);
    const navigate = useNavigate();

    useEffect(() => {
        if (bsQrs.length <= 0) return;
        
        if (bsQrs.length === 1) {
            setUser(prev => ({ ...prev, qrId: bsQrs[0].id }));
        } else {
            setUser(prev => ({ ...prev, qrId: 'select_one' }));
        };
    },[bsQrs]);

    const handleAddQr = async (org_id) => {
        const date = new Date();
        const qrObj = {
            active: true,
            bitsignal: { uid: bsUid, authenticated: true },
            category: null,
            created_at: date,
            microsite: '',
            nickname: 'BitSignal',
            org_id: org_id,
            qr_type: 'bitsignal',
            qr_style: {
                shape: 'square',
                width: 300,
                height: 300,
                margin: 0,
                qrOptions: {
                    typeNumber: '0',
                    mode: 'Byte',
                    errorCorrectionLevel: 'M'
                },
                data: `https://lqr.ai/${qrId}`,
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                    crossOrigin: 'anonymous'
                },
                dotsOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                backgroundOptions: {
                    color: '#ffffff',
                    gradient: null
                },
                image: null,
                cornersSquareOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                },
                cornersDotOptions: {
                    type: 'square',
                    color: '#000000',
                    gradient: null
                }
            },
            schedule: null,
            redirect_url: '',
            tags: [],
            updated_at: date
        };
        
        try {
            await createLiquidQR(qrId, qrObj);
            return qrId;
        } catch (err) {
            console.error(err);
            throw(err);
        };
    };

    const handleAuthenticate = async () => {
        if (user.qrId === 'select_one') {
            setUser(prev => ({ ...prev, qrId: '', type: 'select' }));
            return;
        };

        try {
            const qrId = user?.qrId ? user.qrId : await handleAddQr(userObj.org_id);
            await updateUserObject(user.uid, { bitsignal: { uid: bsUid, dateConnected: userObj?.bitsignal?.dateConnected ?? Date.now(), authenticated: true }});
            handlePostMessage({ type: 'connect', uid: user.uid, qrId: qrId, error: '', success: true });
        } catch (err) {
            console.error(err);
            if (err === 'to_delete' || err === 'to_select') {
                return;
            };
            handlePostMessage({ type: 'connect', uid: '', qrId: '', error: 'Could not update userObj', success: true });
        };
    };

    const handlePostMessage = async (obj) => {
        try {
            window.opener.postMessage(obj, process.env.REACT_APP_BS_ORIGIN);
            window.close();
        } catch (err) {
            console.error(err);
        };
    };

    const handleCreateAccount = async () => {
        setErrObj(prev => ({ ...prev, loading: true }));

        try {
            const userCredentials = await create(user.email, user.password);
            const uid = userCredentials.user.uid;

            const data = {
                bitsignal: {
                    authenticated: false,
                    dateConnected: Date.now(),
                    uid: bsUid
                },
                categories: [],
                credit_balance: 0,
                org_id: uid,
                plan: {
                    analytics: 0,
                    categories: 1,
                    liquid_qrs: 1,
                    plan_id: 0,
                    support: 0,
                    team_size: 1
                },
                sonar_length: 3600000,
                team: [uid],
                timezone: formattedTimezone
            };
            await addUIDtoDB(data);
            await handleAddQr(uid);

            setErrObj(prev => ({ ...prev, loading: false }));
            handlePostMessage({ type: 'connect', uid: uid, qrId: qrId, error: '', success: true });
        } catch (err) {
            if (err === 'incomplete') {
                setErrObj({ error: true, message: 'Error creating account', loading: false });
            };

            console.log('err', err);
            handlePostMessage({ type: 'connect', uid: '', qrId: '', success: false, error: err });
        };
    };

    const handleLogin = async () => {
        setErrObj(prev => ({ ...prev, loading: true }));
        if (user.email && user.password) {
            try {
                const res = await login(user.email, user.password);
                setUser(prev => ({ ...prev, uid: res.user.uid, type: (bsQrs?.length && bsQrs?.length > 1) ? 'select' : (lqrLength >= userObj?.plan?.liquid_qrs) ? 'delete' : 'confirm' }));
                setErrObj({ error: false, message: '', loading: false });
            } catch (err) {
                if (err.code === 'auth/user-not-found') {
                    setErrObj({ error: true, message: 'User not found.', loading: false });
                } else if (err.code === 'auth/wrong-password') {
                    setErrObj({ error: true, message: 'Password is incorrect.', loading: false });
                } else {
                    setErrObj({ error: true, message: 'An error occured while signing in.', loading: false });
                };
            };
        };
    };

    const maskEmail = (email) => {
        if (!email) return '';
        const [name, domain] = email.split('@');
        const maskedName = name.slice(0, 2) + '*'.repeat(name.length - 1);
        return `${maskedName}@${domain}`;
    };

    const handleChangeType = (e) => {
        const loginType = e.target.id;
        setUser({ uid: '', email: '', password: '', type: loginType });
        setErrObj({ error: false, message: '', loading: false });
        if (loginType === 'new') logout();
    };

    const handleDeactivateQr = async () => {
        if (user.type === 'select') {
            setUser(prev => ({ ...prev, qrId: selectedQr.obj.id, type: 'confirm' }));
            return;
        };

        setErrObj(prev => ({ ...prev, loading: true }));

        try {
            const res = await deactivateQR(selectedQr.obj.id);
            if (res.status === 202) {
                setUser(prev => ({ ...prev, type: 'confirm' }));
            } else {
                throw('Error deleting');
            };
        } catch (err) {
            console.error(err);
            setErrObj({ error: true, message: 'There was an error deleting a QR', loading: false });
            setUser(prev => ({ ...prev, type: 'confirm' }));
        };
    };

    const handleBodyReturn = () => {
        switch (user.type) {
            case 'select':
            case 'delete':
                const isDelete = user.type === 'delete'; 
                return (
                    <div className={styles.deleteContainer}>
                        <h3 style={{marginBottom: '1rem'}} >{isDelete ? 'Cannot add new QR' : 'More than one BitSignal QR'}</h3>
                        {isDelete ? 
                            <span>You have reached the maximum number of QRs allowed for your plan. Please delete a QR or <button className={styles.loginBtn} onClick={() => navigate(`/upgrade?tier=${userObj.plan.plan_id}`) } >upgrade your plan here</button>.</span>
                        :
                            <span>You have more than one BitSignal type QR. Please select one to use as your default BitSignal QR.</span>
                        }
                        <QRList setParent={setSelectedQr} liquidQRs={isDelete ? liquidQRs : bsQrs} categories={orgObj?.categories} title='' message='' buttonText={isDelete ? 'Delete' : 'Select'} generatedQR={null} handleDeactivateQr={handleDeactivateQr} />
                    </div>
                );
            case 'conflicting':
                return (
                    <>
                    <h3 style={{marginBottom: '1rem'}} >Authenticated to a Different User</h3>
                    <span>You are already authenticated for a different account. Replace existing authentication?</span>
                    <div className={styles.btnsContainer}>
                        <button className={styles.btn} onClick={handleAuthenticate} >Replace</button>
                        <button className={styles.btn} id='new' onClick={handleChangeType} >Cancel</button>
                    </div>
                    </>
                );
            case 'confirm':
                return (
                    <>
                    <h3 style={{marginBottom: '1rem'}} >Log In to Liquid QR</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }} >
                        <span>Is this your email?</span>
                        <span>{maskEmail(user.email)}</span>    
                    </div>
                    <div className={styles.btnsContainer} >
                        <button className={styles.btn} onClick={handleAuthenticate} >Authenticate</button>
                        <button className={styles.btn} id='new' onClick={handleChangeType} >Cancel</button>
                    </div>
                    </>
                );
            case 'login':
            case 'new':
            default:
                const isNew = user.type === 'new'
                return (
                    <>
                    <h3 style={{marginBottom: '1rem'}} >{isNew ? 'Create Liquid QR account' : 'Log In to Liquid QR'}</h3>
                    <span style={{fontSize: '0.9rem', marginBottom: '1.25rem'}}>{isNew ? 'Already have an account?' : 'Don\'t have an account?'} <button className={styles.loginBtn} id={isNew ? 'login' : 'new'} onClick={handleChangeType} >{isNew ? 'Sign in here' : 'Create an account here'}</button>!</span>
                    <div className={styles.inputGroup} style={{marginBottom: '1rem'}}>
                        <input
                            type='email'
                            id='email'
                            value={user.email}
                            className={styles.input}
                            onChange={(e) => setUser(prev => ({ ...prev, email: e.target.value }))}
                            autoComplete={isNew ? 'off' : 'on'}
                            required
                        />
                        <label htmlFor='email' className={styles.label} style={(isNew && user.email && !emailCheck.valid) ? {color: 'red'} : null}>Email</label>
                        {isNew ?
                            <div className={styles.icon}>
                                <i 
                                    className={!user.email ? 'fas fa-dash' : emailCheck.valid ? 'far fa-check-circle' : 'far fa-times-circle'} 
                                    style={!user.email ? {color: 'grey'} : emailCheck.valid ? {color: 'green'} : {color: 'red'}}
                                />
                            </div> 
                        :
                            <></>
                        }
                    </div>
                    <div className={styles.inputGroup} style={{marginBottom: '1rem'}}>
                        <input
                            type='password'
                            id='password'
                            value={user.password}
                            className={styles.input}
                            onChange={(e) => setUser(prev => ({ ...prev, password: e.target.value }))}
                            required
                        />
                        <label htmlFor='password' className={styles.label}>Password</label>
                    </div>
                    <button className={styles.btn} onClick={isNew ? handleCreateAccount : handleLogin} disabled={!user.email || !user.password || (isNew && (errObj.error || !emailCheck.valid)) || errObj.loading} >{isNew ? 'Create Account' : 'Log In'}</button>
                    {!isNew ? 
                        <a className={styles.forgotPassword} href={`${window.location.origin}/reset`} >Forgot your password?</a>
                    :
                        <></>
                    }
                    </>
                );
        };
    };

    useEffect(() => {
        if (userObj?.hasOwnProperty('bitsignal')) {
            if (userObj?.bitsignal?.uid === bsUid) {
                setUser(prev => ({ ...prev, email: currentUser.email, type: 'confirm'}));
            } else if (userObj?.bitsignal?.uid !== bsUid) {
                setUser(prev => ({ ...prev, type: 'conflicting'}));
            };
        };
    },[bsUid, currentUser?.email, userObj?.bitsignal, setUser]);

    if (!bsUid) {
        window.opener.postMessage('missing uid', process.env.REACT_APP_BS_ORIGIN);
        window.close();
        return <></>;
    };

    return (
        <div className={styles.wrapper} >
            <div className={styles.container} >
                <div className={styles.body} >
                    <img src={logo} height='100'></img>
                    {handleBodyReturn()}
                    {errObj?.error ?
                        <span className={styles.authErrorMsg} >{errObj.message}</span>
                    :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
};

export default Authenticate;