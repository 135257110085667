import { useState, useEffect, useMemo } from 'react';

export const useStep = (signUpComplete) => {
    const [state, setState] = useState(signUpComplete ? 3 : 0);

    useEffect(() => {
        if (signUpComplete === false && state !== 3) {
            setState(3);
        };
    },[signUpComplete, state, setState]);

    return useMemo(() => [state, setState],[state]);
};