import { useState, useEffect, useMemo } from 'react';

export const useRedirectParam = (redirect) => {
    // As redirects are needed, add to array
    // *** alternatively, store in db and return value based on db object
    return useMemo(() => {
        switch (redirect) {
            case 'edit':
                return '/collection/edit';
            case 'collection':
                return '/collection';
            default:
                return null;
        };
    }, [redirect]);
};
