import { useState, useEffect, useMemo } from 'react';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';

export const useHandleTimezone = () => {
    const { options } = useTimezoneSelect('original', allTimezones);
    const [ timezone ] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [ formattedTimezone, setFormattedTimezone ] = useState(null);

    useEffect(() => {
        if (timezone) {
            options.forEach(option => {
                if (option.value === timezone) {
                    setFormattedTimezone({label: option.label, offset: option.offset, value: option.value})
                };
            });
        };
    }, [timezone, options, setFormattedTimezone]);

    return useMemo(() => formattedTimezone, [formattedTimezone]);
};