import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling from 'pp-qr-code';
import QRCard from './QRCard';
import { Link } from 'react-router-dom';
import styles from './QRList.module.css';

const QR = ({ options }) => {
    const qrRef = useRef();
    const code = new QRCodeStyling();

    useEffect(() => {
        if (qrRef.current.children[0]) {
            return;
        };
        if (options) {
            code.update(options);
            code.append(qrRef.current);
        };

        return () => { code.update(options); }
    },[options, code]);

    return (
        <div className={styles.genContainer} >
            <div ref={qrRef} className={styles.qrRef} />
        </div>
    );
};

const QRList = (props) => {
    const { setParent, liquidQRs, categories, title, message, generatedQR, handleDeactivateQr, buttonText } = props;
    const [ filters, setFilters ] = useState({
        searchValue: '',
        categoryValue: '',
        searchType: 'name',
        sortBy: '',
    });

    const { searchValue, categoryValue, searchType, sortBy } = filters;
    const [ searchReturnArr, setSearchReturnArr ] = useState(null);
    const [ categoryDisplay, setCategoryDisplay ] = useState('');

    const handleSearchType = (type) => {
        setFilters(prev => {
            return {
                ...prev,
                searchValue: '',
                searchType: type
            };
        });
    };

    const handleSearchValue = (e) => {
        e.preventDefault();

        setFilters(prev => {
            return {
                ...prev,
                searchValue: e.target.value
            };
        });
    };

    const handleCategoryFilter = (e) => {
        e.preventDefault();

        if (e.target.value === 'Clear Filter') {
            setFilters(prev => {
                return {
                    ...prev,
                    categoryValue: '',
                }
            });
        } else {
            setFilters(prev => {
                return {
                    ...prev,
                    categoryValue: e.target.value
                };
            });
        };
    };

    useEffect(() => {

        const handleSortByFilter = (arr) => {
            // Sort helper for newest/oldest
            // check out this StackOverflow for explanation of Space Ship Operator 
            // https://stackoverflow.com/a/9175783
            const sortHelper = (a,b) => (a < b) - (a > b);
            let result;
            switch(sortBy) {
                case 'newest':
                    result = arr.sort((a,b) => sortHelper((a.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (b.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(b?.data?.nickname?.toLowerCase(), a?.data?.nickname?.toLowerCase()));
                    break;
                case 'oldest':
                    result = arr.sort((a,b) => sortHelper((b.data?.created_at ?? '2024-01-01T00:00:00.000Z'), (a.data?.created_at ?? '2024-01-01T00:00:00.000Z')) || sortHelper(a?.data?.nickname?.toLowerCase(), b?.data?.nickname?.toLowerCase()));
                    break;
                case 'alpha-asc':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                case 'alpha-des':
                    result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() < b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    break;
                default:
                    if (!sortBy) {
                        result = arr.sort((a,b) => a?.data?.nickname?.toLowerCase() > b?.data?.nickname?.toLowerCase() ? 1 : -1);
                    } else {
                        result = arr.filter(qr => qr?.data?.qr_type === sortBy).sort((a,b) => b?.data?.nickname?.toLowerCase() > a?.data?.nickname?.toLowerCase() ? 1 : -1);
                    };
                    break;
            };
            return handleCategoryFilter(result);
        };

        const handleCategoryFilter = (arr) => {
            if (categoryValue) {
                const value = categories?.filter(cat => cat.id === categoryValue)[0]?.name;
                setCategoryDisplay(value);
                if (value === 'Uncategorized') {
                    return handleSearchFilter(arr.filter(obj => !obj.data.category));
                } else {
                    return handleSearchFilter(arr.filter(obj => obj.data.category && obj.data.category === categoryValue));
                };
            } else {
                setCategoryDisplay('');
                return handleSearchFilter(arr);
            };
        };

        const handleSearchFilter = (arr) => {
            if (searchValue?.length > 0) {
                if (searchType === 'name' && searchValue) {
                    return arr.filter(obj => obj.data.nickname.toLowerCase().includes(searchValue.toLowerCase()));
                } else if (searchType === 'tag' && searchValue) {
                    return arr.filter(obj => {
                        if (obj.data.tags?.length > 0) {
                            const tempArr = obj.data.tags.filter(tag => tag.toLowerCase().includes(searchValue.toLowerCase()));
                            if (tempArr.length > 0) {
                                return obj;
                            } else return null;
                        } else {
                            return null;
                        }
                    });
                };
            } else {
                return arr;
            };
        };

        setSearchReturnArr(handleSortByFilter([...liquidQRs]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchValue, categoryValue, searchType, liquidQRs, sortBy]);

    const resultReturn = () => {
        if (searchReturnArr?.length > 0) {
            return (
                <div className={styles.resultMessage}>
                    {searchValue?.length > 0 ? <div className={styles.resultMessageInfo}>Search: "<span style={{fontWeight: 'bold'}}>{searchValue}</span>"</div> : <></>}
                    {categoryValue?.length > 0 ? <div className={styles.resultMessageInfo}>Category: "<span style={{fontWeight: 'bold'}}>{categoryDisplay}</span>"</div> : <></>}
                </div>
            );
        } else {
            return <div className={styles.resultMessage}>No QRs were found. Try another search or category.</div>
        };
    };

    const headerReturn = () => {
        if (generatedQR) {
            return (
                <div className={styles.headerWrap}>
                    <QR options={generatedQR} />
                    <div className={styles.headerInfo}>
                        <h4 className={styles.title}>{title}</h4>
                        {message ? <span>{message}</span> : <></>}
                        <Link to='/collection' className={styles.cancelBtn}>Cancel</Link>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                <h4 className={styles.title}>{title}</h4>
                {message ? <span>{message}</span> : <></>}
                </>
            );
        };
    };

    return(
        <div className={styles.container}>
            {headerReturn()}
            <div className={styles.searchContainer}>
                <div className={styles.searchInner}>
                    <label htmlFor='search-name'>Search by Name</label>
                    <input type='radio' id='search-name' checked={searchType === 'name'} onChange={() => handleSearchType('name')} />
                    <label htmlFor='search-tag'>Search by Tag</label>
                    <input type='radio' id='search-tag' checked={searchType === 'tag'} onChange={() => handleSearchType('tag')} />
                </div>
                <input type='text' placeholder='Search...' className={styles.input} value={searchValue} onChange={handleSearchValue} maxLength='30'></input>
                {categories?.length > 0 ?
                    <div className={styles.selectGroup}>
                        <select className={styles.select} value={categoryValue === '' ? '0' : categoryValue} onChange={handleCategoryFilter} disabled={!categories}>
                            <option value='0' disabled>Category Filter</option>
                            {categories.map((cat, i) => (
                                <option key={i} value={cat.id}>{cat.name}</option>
                            ))}
                            <option value='Clear Filter'>Clear Category Filter</option>
                        </select>
                    </div>
                : <></>}
            </div>
            <div className={styles.resultContainer}>
                {resultReturn()}
            </div>
            {searchReturnArr?.length > 0 ?
                <div className={styles.qrListCard}>
                    {searchReturnArr.filter(qr => qr.data.qr_type !== 'liquid_link').map((qr, i) => (
                        <QRCard key={i} QR={qr} setParent={setParent} handleDeactivateQr={handleDeactivateQr} buttonText={buttonText} />
                    ))}
                </div>
                : <></>
            }
        </div>
    );
};

export default QRList;