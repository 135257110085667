import React, { useMemo, useEffect, useRef } from 'react';
import QRCodeStyling from 'pp-qr-code';

const QRRef = (props) => {
    const { qr_options, loading } = props;
    const QR = useMemo(() => new QRCodeStyling(qr_options),[]);
    const qrRef = useRef(null);
    
    useEffect(() => {
        if (qrRef.current && !loading) QR.append(qrRef.current);
    }, [QR, qrRef, loading]);

    useEffect(() => {
        if (!QR || loading) return;
        QR.update(qr_options);
    }, [QR, loading, qr_options]);

    return (
        <div ref={qrRef} className='ref-container' />
    );
};

export default QRRef;