import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import CustomDownload from '../../CustomDownload';
import QRCodeStyling from 'pp-qr-code';
import { handleQRReset, handleTempObj } from '../../../../../utils/QRFunctions/downloads';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import API from '../../../../../API';
import QRCardDetails from '../../QRCardDetails';
import LinkedQRDisplay from '../LinkedQRDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import styles from './Grid.module.css';

export default function Grid(props) {

    const { QR, orgId, analytics, setQRtoEdit, setDisplay, dataDispatch, optionsDispatch, childQRs } = props;
    const { deactivateQR, deleteFile } = API;

    const qrRef = useRef(null);
    const deleteRef = useRef(null);
    const navigate = useNavigate();

    const { ref, inView } = useInView({
        root: null,
        rootMargin: '0px 0px -25px',
        threshold: .25
    });

    const [ showDownloadModal, setShowDownloadModal ] = useState(false);
    const [ scanCount, setScanCount ] = useState(0);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);
    const [ showChildren, setShowChildren ] = useState(false); // used to view linked qrs
    const [ icon ] = useState(() => {
        if (QR?.data?.qr_type) {
            switch(QR?.data.qr_type) {
                case 'asset':
                    return 'fas fa-shelves';
                case 'microsite':
                    return 'fas fa-square-list';
                case 'location':
                    return 'fas fa-map-location';
                case 'link':
                    return 'fas fa-link-simple';
                case 'pdf':
                    return 'fas fa-file-pdf';
                case 'menu':
                    return 'fas fa-fork-knife';
                case 'appointment':
                    return 'fas fa-calendar-days';
                case 'tracker':
                    return 'fas fa-clipboard-list-check';
                case 'survey':
                    return 'fa-regular fa-square-poll-vertical';
                case 'bitsignal':
                    return 'fak fa-bitsignal';
                default:
                    return '';
            };
        };
    });

    const options = QR?.data?.qr_style;
    const [ code, setCode ] = useState(inView ? new QRCodeStyling(options) : null);

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            if (code && inView && options) {
                code.update(options);
                code.append(qrRef.current);
            };
        };
    };

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => document.removeEventListener("visibilitychange", onVisibilityChange);
    }, []);

    useEffect(() => {
        if (inView && !code && options) {
            setCode(() => new QRCodeStyling(options));
        } else if (inView && code && options) {
            code.update(options);
            code.append(qrRef.current);
        };
    },[inView, code, options, setCode]);

    useEffect(() => {
        if (analytics) {
            if (analytics[QR?.id]) {
                setScanCount(analytics[QR?.id].length);
            } else {
                setScanCount(0);
            };
        } else {
            setScanCount(0);
        };
    },[QR, analytics]);
    // end onload and update

    // ----- DOWNLOAD QR -----
    const handleDownload = async (type, size, fileName, correction) => {
        const downloadCode = new QRCodeStyling(options);
        const tempObj = handleTempObj(options);
        options.imageOptions = {
            ...options.imageOptions,
            crossOrigin: 'anonymous'
        };
        if (size) {
            options.width = size;
            options.height = size;
        };
        if (!fileName) {
            fileName = 'QRcode';
        };
        if (!type) {
            type = 'png';
        };
        if (correction) {
            options.qrOptions.errorCorrectionLevel = correction;
        };

        downloadCode.update(options);
        await downloadCode.download({extension: type, name: fileName});
        downloadCode.update(handleQRReset(options, tempObj));
    };

    const handleCustomDownloadClick = () => {
        setShowDownloadModal(true);
    };
    // ----- END DOWNLOAD QR -----
    // ----------------------------------------------

    const handleEditQR = () => {
        setQRtoEdit(QR);
        setDisplay('edit-qr');
        optionsDispatch({type: 'edit', options: QR.data.qr_style});
        dataDispatch({type: 'edit', data: QR.data});
        navigate('/collection/edit');
    };

    const handleShowDeleteConfirm = () => {
        setShowConfirm(true);
        let btn = document.getElementById(`qr-delete-${QR.id}`);
        btn?.classList.add(styles.confirm);

        setTimeout(() => {
            let words = document.querySelector(`.${styles.deleteContent}.qr-${QR.id}`);
            words?.classList.add(styles.confirm);
        },250);
    };

    const handleShowDelete = () => {
        setShowDelete(true);
    };

    const handleHideDelete = () => {
        setShowDelete(false);
        setShowConfirm(false);
    };

    const cancelDelete = () => {
        setShowConfirm(false);
        let btn = document.getElementById(`qr-delete-${QR.id}`);
        btn?.classList.remove(styles.confirm);
    };

    const handleDelete = async () => {
        if (QR.data.qr_type === 'pdf') {
            QR.data.pdf.forEach(pdf => deleteFile(pdf.redirect));
        } else if (QR.data.qr_type === 'menu') {
            QR.data.menu.forEach(obj => {if (obj.redirect.includes(QR.id)) deleteFile(obj.redirect)});
        } else if (QR.data.qr_type === 'asset' && QR.data.asset?.route?.length > 0) {
            QR.data.asset.route.forEach(fileURL => {
                deleteFile(fileURL);
            });
        };

        await deactivateQR(QR.id)
            .catch(err => console.log('Failed to delete QR.'));
    };

    useEffect(() => {
        if (window.innerWidth <= 768) {
            handleShowDelete();
        };
    },[]);

    useEffect(() => {
        const watcher = (e) => {
            if (!deleteRef.current || (deleteRef.current.contains(e.target) && (e.target.id !== `cancel-x-${QR?.id}` && e.target.id !== `delete-check-${QR?.id}`))) {
                return;
            } else {
                let btn = document.getElementById(`qr-delete-${QR.id}`);
                btn?.classList.remove(styles.confirm);
                setShowConfirm(false);
            };
        };
        window.addEventListener('click', watcher);
        return () => window.removeEventListener('click', watcher);
    },[QR?.id]);

    const handleFlip = () => {
        const front = document.querySelector(`.front-${QR.id}`);
        const back = document.querySelector(`.back-${QR.id}`);

        front?.classList.contains(styles.flip) ? front?.classList.remove(styles.flip) : front?.classList.add(styles.flip);
        back?.classList.contains(styles.flip) ? back?.classList.remove(styles.flip) : back?.classList.add(styles.flip);
    };

    return (
        <>
        <div className={styles.card} id={QR?.id} onMouseEnter={window.innerWidth > 768 ? handleShowDelete : null} onMouseLeave={window.innerWidth > 768 ? handleHideDelete : null} ref={ref} >
            <div className={`${styles.back} back-${QR.id}`}>
                <QRCardDetails QR={QR} scanCount={scanCount} handleFlip={handleFlip} childQRs={childQRs} showChildren={showChildren} setShowChildren={setShowChildren} />
            </div>
            <div className={`${styles.front} front-${QR.id}`}>
                <div className={styles.iconType}><i className={icon} /></div>
                <div className={styles.content}>
                    <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        trigger={['click','hover']}
                        overlay={<Tooltip>{QR.data.nickname}</Tooltip>}
                    >
                        <div className={styles.contentInner} title={QR.data?.nickname ? QR.data.nickname : QR.nickname}><span>{QR.data?.nickname ? QR.data.nickname : QR.nickname}</span></div>
                    </OverlayTrigger>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.codeWrap}>
                    <div className={styles.code} ref={qrRef} onClick={() => window.open(`https://lqr.ai/${QR.id}?orgId=${orgId}`, '_blank')}>{!code ? <i className='fa-duotone fa-spinner fa-spin' /> : <></>}</div>
                    <div className={styles.codeWrapInner}>
                        <div className={styles.scanCount}><i className='fas fa-eye me-1' />{scanCount}</div>
                        {childQRs?.length > 0 ? 
                            <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                trigger={['click','hover']}
                                overlay={<Tooltip>View Linked QRs</Tooltip>}
                            >
                                <button className={styles.childBtn} onClick={() => setShowChildren(true)}><i className='fa-regular fa-object-exclude me-1' />{childQRs?.length}</button> 
                            </OverlayTrigger>
                            : <></>
                        }
                        <button className={styles.flipBtn} onClick={handleFlip}><i className='fas fa-circle-info' /></button>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.btnContainer} >
                    <button className={styles.edit} style={{marginBottom: '0'}} onClick={handleEditQR}>Edit</button>
                    <Dropdown>
                        <Dropdown.Toggle id='qr-download' className={styles.download}><i className='fas fa-download me-3' /></Dropdown.Toggle>
                        <Dropdown.Menu className={styles.dropdownMenu}>
                            <Dropdown.Item onClick={() => handleDownload('png', null, QR.data?.nickname.replace(/\s+/g, ''))}>PNG</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDownload('svg', null, QR.data?.nickname.replace(/\s+/g, ''))}>SVG</Dropdown.Item>
                            <Dropdown.Item onClick={handleCustomDownloadClick}>Add a Frame!</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {showDelete &&
                    <button className={styles.delete} id={`qr-delete-${QR?.id}`} ref={deleteRef} onClick={handleShowDeleteConfirm}>
                        {showConfirm ? 
                            <div className={`${styles.deleteContent} qr-${QR.id}`}>
                                <span className='me-4'>Are you sure?</span>
                                <div id={`delete-check-${QR?.id}`} className={styles.deleteCheck} style={{marginRight: '.5rem'}} onClick={handleDelete}><i className='fas fa-check' style={{pointerEvents: 'none'}}></i></div>
                                <span style={{width: '.5rem', borderRight: '1px solid #ccc', marginRight: '.5rem'}} />
                                <div id={`cancel-x-${QR?.id}`} className={styles.cancelX} onClick={cancelDelete}><i className='fas fa-xmark' style={{pointerEvents: 'none'}}></i></div>
                            </div>
                            :
                            <i className='fas fa-xmark' style={{pointerEvents: 'none'}}></i>
                        }  
                    </button>
                }
                <div className={styles.extraSpace}></div>
            </div>
        </div>
        {showDownloadModal ? 
            <CustomDownload QR={QR} showDownloadModal={showDownloadModal} setShowDownloadModal={setShowDownloadModal} nickname={QR.data?.nickname} />
        : <></>
        }
        <Modal className={styles.linkedModal} show={showChildren} onHide={() => setShowChildren(false)}>
            <Modal.Header closeButton><span>Linked QR(s)</span></Modal.Header>
            <Modal.Body>
                <Swiper
                    key={Date.now()}
                    effect={'coverflow'}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    watchOverflow={true}
                    loop={childQRs?.length > 3 ? true : false}
                    observer={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className={styles.qrSwiper}
                >
                    {childQRs.map((qr,index) => (
                        <SwiperSlide key={qr.id} className={styles.qrSwiperSlide}>
                            <LinkedQRDisplay 
                                QR={qr} 
                                setQRtoEdit={setQRtoEdit} 
                                setDisplay={setDisplay} 
                                optionsDispatch={optionsDispatch}
                                dataDispatch={dataDispatch}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Modal.Body>
        </Modal>
        </>
    );
};