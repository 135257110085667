import axios from 'axios';
import { auth } from '../firebase';

const authToken = () => auth.currentUser.getIdToken(true);

export default {
    getUserByEmail: async (email) => {
        return await axios.get(`/api/admin/email/${email}`);
    },

    decryptBsPassword: async (obj) => {
        return await axios.post('/api/admin/decrypt', obj);
    },
    
    uploadCodesToAirtable: async (data) => {
        return await authToken().then(async token => {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
    
            return await axios.post(`/api/admin/airtable`, data, config);
        });
    },
    
    completeBSEntAccount: async (obj) => {
        return await authToken().then(async token => {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            return await axios.post(`/api/admin/bsent`, obj, config);
        });
    },
};
