import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import getTotalScansWeek from '../../../utils/ChartFunctions/getTotalScansWeek'
import chartLabels from '../../../utils/ChartFunctions/getWeekLabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

export const options = {
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: false,
      text: 'LiquidQR Scans this Week',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const data = {
  labels: chartLabels,
  datasets: [
    {
      label: 'LiquidQR Scans',
      data: [0, 0, 0, 0, 0, 0, 0],
      borderColor: '#607aa4',
      backgroundColor: '#607aa4',
    }
  ],
};

export const updateData = (chart, chartData) => {
  chart.data.datasets.forEach((dataset) => {
    dataset.data = chartData
  })

  chart.update()
}

export function DashChart(props) {

  const { analytics, liquidQRs } = props
  const chartRef = useRef(null);

  useEffect(() => {

    if(analytics){
      const dataReturn = getTotalScansWeek(analytics)

      if(dataReturn){
        const chart = chartRef.current; 

        updateData(chart, dataReturn);
      }

    }

  }, [analytics, liquidQRs])

  return <Line options={options} data={data} ref={chartRef} />;
}