import React from 'react';
import Dots from './Dots';
import Background from './Background';
import CornerFrame from './CornerFrame';
import CornerEye from './CornerEye';
import Logo from './Logo';
import Shape from './Shape';
import styles from './Style.module.css';

export default function Style(props) {
    const { qr_options, optionsDispatch, display, setDisplay, setLogo } = props;

    return (
        <>
        <div className={styles.container}>
            <h5>Customization</h5>
            <div className={styles.radioContainer}>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('shape')} id='shape' checked={display === 'shape'} />
                <label htmlFor='shape' className={styles.label}>
                    <span className={styles.title}>Shape</span>
                    <span className={qr_options?.shape === 'square' ? styles.square : `${styles.square} ${styles.circle}`} />
                </label>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('dots')}  id='dots' checked={display === 'dots'} />
                <label htmlFor="dots" className={styles.label}>
                    <span className={styles.title}>Dots</span>
                    <span className={styles.color} style={qr_options?.dotsOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options?.dotsOptions?.gradient?.colorStops[0]?.color}, ${qr_options?.dotsOptions?.gradient?.colorStops[1]?.color})`} : !qr_options?.dotsOptions?.gradient ? {backgroundColor: qr_options?.dotsOptions?.color} : {backgroundColor: '#fff'}} />
                </label>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('background')} id='background' checked={display === 'background'} />
                <label htmlFor='background' className={styles.label}>
                    <span className={styles.title}>Background</span>
                    <span className={styles.color} style={qr_options?.backgroundOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.backgroundOptions.gradient.colorStops[0].color}, ${qr_options.backgroundOptions.gradient.colorStops[1].color})`} : !qr_options?.backgroundOptions?.gradient ? {backgroundColor: qr_options.backgroundOptions.color} : {backgroundColor: '#fff'}} />
                </label>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('corner-frame')} id='corner-frame' checked={display === 'corner-frame'} />
                <label htmlFor="corner-frame" className={styles.label}>
                    <span className={styles.title}>Corner Frame</span>
                    <span className={styles.color} style={qr_options?.cornersSquareOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.cornersSquareOptions.gradient.colorStops[0].color}, ${qr_options.cornersSquareOptions.gradient.colorStops[1].color})`} : !qr_options?.cornersSquareOptions?.gradient ? {backgroundColor: qr_options.cornersSquareOptions.color} : {backgroundColor: '#fff'}} />
                </label>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('corner-eye')} id='corner-eye' checked={display === 'corner-eye'} />
                <label htmlFor='corner-eye' className={styles.label}>
                    <span className={styles.title}>Corner Eye</span>
                    <span className={styles.color} style={qr_options?.cornersDotOptions?.gradient ? {background: `linear-gradient(to right, ${qr_options.cornersDotOptions.gradient.colorStops[0].color}, ${qr_options.cornersDotOptions.gradient.colorStops[1].color})`} : !qr_options?.cornersDotOptions?.gradient ? {backgroundColor: qr_options.cornersDotOptions.color} : {backgroundColor: '#fff'}} />
                </label>
                <input type='radio' className={styles.radio} onChange={() => setDisplay('logo')} id='logo' checked={display === 'logo'} />
                <label htmlFor="logo" className={styles.label}>
                    <span className={styles.title}>Logo</span>
                    <span className={styles.logo}>{qr_options?.image ? <i className='far fa-image' /> : <i className='far fa-image-slash' />}</span>
                </label>
            </div>
        </div>
        {display === 'logo' ?
            <Logo setLogo={setLogo} />
        : display === 'dots' ?
            <Dots qr_options={qr_options} optionsDispatch={optionsDispatch} />
        : display === 'background' ?
            <Background qr_options={qr_options} optionsDispatch={optionsDispatch} />
        : display === 'corner-frame' ?
            <CornerFrame qr_options={qr_options} optionsDispatch={optionsDispatch} />
        : display === 'corner-eye' ?
            <CornerEye qr_options={qr_options} optionsDispatch={optionsDispatch} />
        : display === 'shape' && 
            <Shape qr_options={qr_options} optionsDispatch={optionsDispatch} />
        }
        </>
    );
};