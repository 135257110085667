import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, onSnapshot, terminate } from "firebase/firestore";
import { firestore } from '../../firebase';
import { getLiquidListener, getAnalytics, getOrganization } from './activeListeners';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
};

export function AuthProvider({ children }) {
    // ----- STATE INITIALIZATION -----
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [userObj, setUserObj] = useState(null);
    const [liquidQRs, setLiquidQRs] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const [orgObj, setOrgObj] = useState(null);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    function create(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    // browserLocalPersistence sets the authentication reference to localStorage opposed to indexedDB or cookies, which others claim is causing issues on iOS
    const login = (email, password) => setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password))
        .catch(err => {
            throw(err);
    });

    const logout = () => auth.signOut();

    const refreshCurrentUser = async ({ email, password }) => {
        try {
            login(email, password).then(async() => {
                await auth.currentUser.getIdToken(true);
                await auth.currentUser.reload();
                setCurrentUser(auth.currentUser)
            })
        } catch (err) {
            console.error(err);
            throw(new Error(err));
        };
    };

    useEffect(() => {
        let liquidQRListener, analyticsListener, organizationListener;
        if (userObj) {
            liquidQRListener = getLiquidListener(userObj.org_id, setLiquidQRs);
            analyticsListener = getAnalytics(userObj.org_id, setAnalytics);
            organizationListener = getOrganization(userObj.org_id, setOrgObj);
        };
        
        return () => {
            liquidQRListener && liquidQRListener();
            analyticsListener && analyticsListener();
            organizationListener && organizationListener();
        };
    }, [userObj, userObj?.org_id]);

    useEffect(() => {
        let unsubscribe;
        if (currentUser) {
            unsubscribe = onSnapshot(doc(firestore, "users", currentUser.uid), snapshot => {
                if (snapshot.exists()) {
                    setUserObj(snapshot.data());
                };
            });
        } else {
            setUserObj(null);
        };

        return () => {
            setUserObj(null);
            unsubscribe && unsubscribe();
        };
    }, [currentUser]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            };

            setLoading(false);
        });

        return () => unsubscribe;
    });

    useEffect(() => {
        const unloadCallback = async () => {
            try {
                await terminate(firestore);
            } catch (e) {
                console.log(e);
            };
        };
        window.addEventListener("beforeunload", unloadCallback);
        return async () => {
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, []);

    const value = {
        currentUser,
        userObj,
        liquidQRs,
        analytics,
        orgObj,
        refreshCurrentUser,
        setLiquidQRs,
        create,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};