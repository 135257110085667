import { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import adminAPI from '../../API/adminAPI';

export const useCheckEmail = ({ email, type }) => {
    const { getUserByEmail } = adminAPI;
    const [ response, setResponse ] = useState({ valid: false, message: '' });

    useEffect(() => {
        if (type !== 'new') return;
        const emailRE = new RegExp(/[^@]+@[^@]+\.[^@]+/);

        const handleGetUserByEmail = debounce(async (email) => {
            try {
                const { data } = await getUserByEmail(email);

                if (data.hasOwnProperty('code') && data.code === 'auth/user-not-found') {
                    setResponse({ valid: true, message: '' });
                } else {
                    setResponse({ valid: false, message: 'Email already in use'});
                };
            } catch (err) {
                console.error(err);
                setResponse({ valid: false, message: 'I dunno something caused a failure' });
            };
        }, 500);

        if (email && emailRE.test(email)) handleGetUserByEmail(email);

        return () => handleGetUserByEmail.cancel();
    },[email, response?.valid, setResponse]);

    return useMemo(() => response, [response]);
};