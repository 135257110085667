import React from 'react';
import { AuthProvider } from './utils/Auth';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { NavigationContextProvider, NavigationConditional } from './utils/NavigationContext/index.js';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import QRCodes from './pages/QRCodes';
import Analytics from './pages/Analytics';
import MicrositeDisplay from './pages/MicrositeDisplay';
import Sonar from './pages/Sonar';
import SetUp from './pages/SetUp';
import FAQ from './pages/FAQ';
import Admin from './pages/Admin';
import Upgrade from './pages/Upgrade';
import Settings from './pages/Settings';
import PasswordResetRequest from './pages/PasswordResetRequest';
import Action from './pages/Action';
import AssetPage from './pages/AssetPage';
import TrackerPage from './pages/TrackerPage';
import SurveyPage from './pages/SurveyPage';
import Scheduling from './pages/Scheduling';
import NotFound from './pages/NotFound';
import FileDisplay from './pages/FileDisplay/index.js';
import Authenticate from './pages/Authenticate/index.js';

function App() {
  return (
    <Router>
      <AuthProvider>
        <NavigationContextProvider >
        <NavigationConditional />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/analytics' element={<Analytics />} />
          <Route path='/collection/:path?' element={<QRCodes />} />
          <Route path='/upgrade' element={<Upgrade />} />
          <Route path='/setup/:id' element={<SetUp />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/reset' element={<PasswordResetRequest />} />
          <Route path='/action' element={<Action />} />
          <Route path='/a/:id' element={<AssetPage />} />
          <Route path='/t/:id' element={<TrackerPage />} />
          <Route path='/s/:id' element={<SurveyPage />} />
          <Route path='/m/:id' element={<FileDisplay />} />
          <Route path='/p/:id' element={<FileDisplay />} />
          <Route path='/site/:id' element={<MicrositeDisplay />} />
          <Route path='/scheduling' element={<Scheduling />} />
          <Route path='/authenticate' element={<Authenticate />} />
          <Route 
            path='/home'
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
            />
          <Route path='/admin' element={
            <PrivateRoute>
                <Admin />
            </PrivateRoute>
            }
          />
          <Route path='/notfound' element={<NotFound />} />
          <Route path='/sonar' element={<Sonar />} />
          <Route path='*' element={<NotFound />} />
          {/* <Route path='/productpage' element={<ProductPage />} /> */}
        </Routes>
        </NavigationContextProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;