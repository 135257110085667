import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../utils/Auth';
import styles from './Tabs.module.css';

export default function Tabs(props) {
    const { qrType, step, setStep } = props;
    const [searchParams] = useSearchParams();
    const ref = searchParams.get('ref');
    const ent = searchParams.get('ent');
    const { orgObj } = useAuth();

    const disabled = useMemo(() => {
        if ((orgObj && orgObj?.hasOwnProperty('signUpComplete')) || (ref && ent === 'true')) { 
            return !orgObj?.signUpComplete;
        } else {
            return false;
        };
    },[orgObj]);

    useEffect(() => {
        let mounted = true;
        const tabs = document.querySelectorAll(`.${styles.tab}`);
        tabs.forEach(tab => {
            const tabNum = tab.id.split('-');
            const num = parseInt(tabNum[1]);
            if ((num === step && !disabled) || (disabled && num === 3)) {
                mounted && tab.classList.add(styles.tabActive);
                mounted && tab.classList.remove(styles.tabShadow);
            } else {
                mounted && tab.classList.remove(styles.tabActive);
                mounted && tab.classList.add(styles.tabShadow);
            };
        });

        return () => mounted = false;
    },[step, setStep]);

    return (
        <div className={styles.container}>
            <div className={disabled ? `${styles.disabled} ${styles.tab}` : `${styles.tab}`} id='tabs-2' onClick={() => !disabled && setStep(2)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4'} : {width: 'calc(100% / 3'}} >
                Details
            </div>
            <div className={disabled ? `${styles.disabled} ${styles.tab}` : `${styles.tab}`} id='tabs-5' onClick={() => !disabled && setStep(5)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4)'} : {width: 'calc(100% / 3'}} >
                Options
            </div>
            <div className={styles.tab} id='tabs-3' onClick={() => setStep(3)} style={(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ? {width: 'calc(100% / 4'} : {width: 'calc(100% / 3'}} >
                Styling
            </div>
            {(qrType === 'link' || qrType === 'pdf' || qrType === 'menu') ?
                <div className={disabled ? `${styles.disabled} ${styles.tab}` : `${styles.tab}`} id='tabs-4' onClick={() => !disabled && setStep(4)} style={{width: 'calc(100% / 4'}} >
                    Schedule
                </div>
            :
                <></>
            }
        </div>
    );
};