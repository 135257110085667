import { firestore } from '../../firebase/index';
import { collection, doc, getDoc } from 'firebase/firestore';
import { customAlphabet } from 'nanoid';
import { badCodes } from '../BadCodes';
import { useEffect, useMemo, useState } from 'react';

export default async function keyGen() {
    try {
        const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789', 6);
        const qrId = nanoid();
        const snapshot = await getDoc(doc(collection(firestore, 'codes'), qrId));
        return (snapshot.exists() || badCodes.includes(qrId.toLowerCase())) ? keyGen(): qrId;
    } catch (err) {
        console.error(err);
        return '';
    };
};

export const useKeyGen = () => {
    const [ id, setId ] = useState('');
    const [ err, setErr ] = useState(false);

    useEffect(() => {
        const handleKeyGen = async () => {
            try {
                setId(await keyGen());
            } catch (error) {
                console.error(error);
                setErr(true);
            };
        };

        if (!id && !err) {
            handleKeyGen();
        };
    },[id, setId, err, setErr]);

    return useMemo(() => id, [id]);
};