import { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import dataURLtoFile from '../QRFunctions/handleLogo';

export const useLogo = ({ setFormData, optionsDispatch, qr }) => {
    const [state, setState] = useState(null);
    const [searchParams] = useSearchParams();
    const edit = useMemo(() => searchParams.has('edit', 'true'), [searchParams]);

    const handleLogo = (val) => {
        if (val) {
            optionsDispatch({type: 'logo', options: val});
            setFormData(dataURLtoFile(val, 'logo.png'));
            setState(val);
        } else {
            setFormData(null);
            optionsDispatch({type: 'remove-logo', options: 'M'});
            setState(null);
        };
    };

    useEffect(() => {
        if (!edit) return;

        if (qr && qr?.data?.qr_style?.image && edit && !state) {
            setState(true);
        } else if (!state && !qr?.data?.qr_style?.image && edit) {
            handleLogo(null);
        };
    },[qr]);

    return useMemo(() => [state, handleLogo], [state]);
};
