import React from 'react';
import AppointmentOptions from '../QRTypes/Appointment/AppointmentOptions';
import AssetOptions from '../QRTypes/Asset/AssetOptions';
import LocationOptions from '../QRTypes/Location/LocationOptions';
import RedirectLinkOptions from '../QRTypes/RedirectLink/RedirectLinkOptions';
import MenuOptions from '../QRTypes/Menu/MenuOptions';
import MicrositeOptions from '../QRTypes/Microsite/MicrositeOptions';
import PDFOptions from '../QRTypes/PDF/PDFOptions';
import TrackerOptions from '../QRTypes/Tracker/TrackerOptions';
import SurveyOptions from '../QRTypes/Survey/SurveyOptions';
import BitSignalOptions from '../QRTypes/BitSignal/BitSignalOptions';

export default function Options(props) {
    const { qrType, qr_data, dataDispatch, id, qrFiles, setQrFiles, setDeleteFiles, filesToDisplay, setFilesToDisplay } = props;

    switch (qrType) {
        case 'appointment':
            return <AppointmentOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'asset':
            return <AssetOptions qr_data={qr_data} dataDispatch={dataDispatch} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} filesToDisplay={filesToDisplay} setFilesToDisplay={setFilesToDisplay} />;
        case 'location':
            return <LocationOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'link':
            return <RedirectLinkOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'menu':
            return <MenuOptions qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} />;
        case 'microsite':
            return <MicrositeOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'pdf':
            return <PDFOptions qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} />;
        case 'tracker':
            return <TrackerOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'survey':
            return <SurveyOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'bitsignal':
            return <BitSignalOptions qr_data={qr_data} dataDispatch={dataDispatch} id={id}/>
        default:
            // what should default be?
            return <></>;
    };
};