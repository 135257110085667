import React, { useState } from 'react';
import LoginComponent from '../../components/LoginComponent';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import { useRedirectParam } from '../../utils/hooks/useRedirectParam';
import styles from './Login.module.css';
import BSEntAuth from '../../components/BSEntAuth';

export default function Login() {
    // ----- STATE INITIALIZATION -----
    const { login, currentUser } = useAuth();
    
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const query = window.location.search;
    const ent = searchParams.get('ent');
    const bsEmail = searchParams.get('e');
    const bsPassword = searchParams.get('p');
    const r = searchParams.get('r');
    const bsTag = searchParams.get('tag');
    const redirect = useRedirectParam(r);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- LOGIN FUNCTION -----
    const handleLogin = async (e) => {
        e.preventDefault();
        await login(email, password)
            .then(() => {
                if (redirect) {
                    navigate(`${redirect}${query}`);
                } else if (searchParams.get('gen_id')) {
                    navigate(`/signup?gen_id=${searchParams.get('gen_id')}`);
                } else if (query) {
                    window.location.assign(window.location.origin + `/upgrade${query}`);
                } else {
                    navigate('/home');
                };
            })
            .catch(() => {
                setError(true);
            });
    };
    // ----- END LOGIN FUNCTION -----
    // ----------------------------------------------

    if (bsEmail && bsPassword && bsTag) {
        return <BSEntAuth type='login' email={bsEmail} password={bsPassword} tag={bsTag} ent={ent} />;
    };

    if (currentUser && (!bsEmail && !bsPassword && !bsTag)) {
        return (<Navigate replace to='/home' />);
    } else {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2 className='mb-2'>Login</h2>
                    <LoginComponent handleLogin={handleLogin} email={email} setEmail={setEmail} password={password} setPassword={setPassword} error={error} setError={setError} />
                </div>
            </div>
        );
    };
};