import React, { useState } from 'react';
import { useAuth } from '../../utils/Auth';
import { Navigate } from 'react-router-dom';
import { getDoc, doc, collection, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import QRCodeStyling from 'pp-qr-code';
import handleLabels from '../../utils/QRFunctions/handleLabels';
import { badCodes } from '../../utils/BadCodes';
import adminAPI from '../../API/adminAPI';
import styles from './Admin.module.css';

export default function Admin() {
    const { currentUser, userObj } = useAuth();
    const { uploadCodesToAirtable } = adminAPI;
    const [ state, setState ] = useState({
        imageType: null,
        num: 0,
        jobId: '',
        csvLink: '',
        error: {
            bool: false,
            message: ''
        },
        loading: false
    });
    const qrTypes = [
        {
            type: 'default',
            label: 'Default',
            value: null
        },
        {
            type: 'scanMe',
            label: 'Scan Me',
            value: 'scanMe'
        },
        {
            type: 'tapMe',
            label: 'Tap Me',
            value: 'tapMe'
        },
        {
            type: 'menu',
            label: 'Menu',
            value: 'menu'
        },
    ];
    const imageFileNames = { tapMe: 'Tap-Me-SVG.svg', scanMe: 'Scan-Me.png', menu: 'Menu.png' };
    const app = getApp();
    const storage = getStorage(app);

    const handleReset = () => {
        setState(prev => ({
            imageType: null,
            num: 0,
            jobId: '',
            csvLink: prev.csvLink,
            error: {
                bool: false,
                message: ''
            },
            loading: false
        }));
    };

    const keyGen = async () => {
        let arr = 'M1NqBaVz2CwXsZxA3eSdDcF4GrfHvJ5KtLgbP6OyIhUn7YuTjRmE8iWk9Qol0p';
        let randCode = '-';
        let idBool = true;
    
        while (idBool === true) {
            for (let i = 0; i < 7; i++) {
                let randNum = Math.floor(Math.random() * 62);
                randCode += arr[randNum];
        
                if (i === 6) {
                    const snapshot = await getDoc(doc(collection(firestore, 'codes'), randCode));
                    if (snapshot.exists() || badCodes.includes(randCode.toLowerCase())) {
                        randCode = '-';
                    } else {
                        idBool = false;
                        return randCode;
                    };
                };
            };
        };
    };

    const saveToDB = async (key, obj) => {
        obj.width = 300;
        obj.height = 300;

        const data = {
            active: true,
            category: null,
            nickname: key,
            qr_type: 'link',
            qr_style: obj,
            redirect_url: `https://app.liquidqr.com/setup/${key}`,
            tags: []
        };
        
        try {
            setDoc(doc(firestore, 'codes', key), data, { merge: true });
        } catch (err) {
            console.error('error');
        };
    };

    const saveToStorage = async ({ key='', file, refLocation }) => {
        const handleStorage = async () => {
            try {
                const storageRef = ref(storage, `retail/${refLocation}`);
                await uploadBytes(storageRef, file);
                return storageRef;
            } catch (err) {
                console.error(err)
                throw(new Error('Failed save to storage'));
            };
        };

        const handleUploadCodes = async (storageRef) => {
            try {
                await uploadCodesToAirtable({
                    type: state.imageType,
                    url: await getDownloadURL(storageRef),
                    name: key,
                    job: state.jobId
                });
            } catch (err) {
                console.error(err)
                throw(new Error('Failed save to airtable'));
            };
        };
        
        try {
            const storageRef = await handleStorage();
            await handleUploadCodes(storageRef);
        } catch (err) {
            console.error(err);
            setState(prev => ({ ...prev, error: { bool: true, message: err.message }, loading: false }));
        };
    };

    const handleCreate = async () => {
        setState(prev => ({ ...prev, error: { bool: false, message: '' }, loading: true }));
        if (state.num > 0) {
            const date = new Date();
            const formatted = `${+date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
            const csv = [];
            for (let i = 0; i < state.num; i++) {
                const key = await keyGen();
                const data = {
                    width: 300,
                    height: 300,
                    margin: 0,
                    qrOptions: {
                        typeNumber:'0',
                        mode:'Byte',
                        errorCorrectionLevel:'M'
                    },
                    data: `https://lqr.ai/${key}`,
                    imageOptions: {
                        hideBackgroundDots:true,
                        imageSize:0.4,
                        margin:0,
                        crossOrigin:'anonymous'
                    },
                    dotsOptions: {
                        type: 'square',
                        color: '#000000',
                        gradient: null
                    },
                    backgroundOptions: {
                        color: '#f6f6f6',
                        gradient: null
                    },
                    image: null,
                    cornersSquareOptions: {
                        type: 'square',
                        color: '#000000',
                        gradient: null
                    },
                    cornersDotOptions: {
                        type: 'square',
                        color: '#000000',
                        gradient: null
                    }
                };

                if (!state.imageType) {
                    csv.push(`https://lqr.ai/${key}`);
                } else {
                    data.image = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/${imageFileNames[state.imageType]}`;
                    data.qrOptions.errorCorrectionLevel = 'H';
                    data.width = 487.5;
                    data.height = 487.5;

                    const qrCode = new QRCodeStyling(data);
                    qrCode.getRawData('png').then(async res => {
                        const file = new File([res], key, {type: 'image/png'});
                        await saveToStorage({ key: key, file: file, refLocation: `${formatted}/${key}.png` });
                    });
                };
                await saveToDB(key, data);
            };

            if (csv.length) {
                const blob = new Blob([csv.join('\n')], {type: 'text/csv'});
                await saveToStorage({ key: '', file: blob, refLocation: `${formatted}/${state.jobId}.csv` });
                setState(prev => ({ ...prev, csvLink: URL.createObjectURL(blob) }));
            };
            handleReset();
        };
    };

    if (currentUser) {
        if (!userObj) return <i className='far fa-spinner fa-spin' />;

        if (userObj?.admin) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <div className={styles.form}>
                            <h2>Create Retail Codes</h2>
                            <div className={styles.btnContainer} style={{justifyContent: 'center'}}>
                            {qrTypes.map(t => (
                                <label className={styles.radioLabel} key={t.type}>
                                    <input id={`${t.type}-label`} type='radio' value={`${t.value}`} className={styles.radio} onChange={() => setState(prev => ({ ...prev, imageType: t.value }))} checked={state.imageType === t.type} />
                                    {state.imageType === t.value ? <i className='far fa-square-check me-1' /> : <i className='far fa-square me-1' /> }
                                    {t.label}
                                </label>
                            ))}
                            </div>
                            <div className={styles.innerWrap}>
                                <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                    <label htmlFor='num-label'>Number of Codes</label>
                                    <input className={styles.inputs} type='number' id='num-label' value={state.num} onChange={e => setState(prev => ({ ...prev, num: e.target.value }))} onBlur={handleLabels} ></input>
                                </div>
                                <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                    <label htmlFor='job-id'>Job ID</label>
                                    <input className={styles.inputs} type='text' id='job-id' value={state.jobId} onChange={e => setState(prev => ({ ...prev, jobId: e.target.value }))} onBlur={handleLabels} ></input>
                                </div>
                                <button onClick={handleCreate} className={styles.btn} disabled={state.num <= 0 || !state.jobId || state.loading}>{state.loading ? <i className='far fa-spinner fa-spin' /> : 'Create'}</button>
                            </div>
                            {state.csvLink ?
                                <a href={state.csvLink} download={`${Date.now()}-csv-links.csv`} >DOWNLOAD LIQUID LABELS</a>
                            :
                                <></>
                            }
                            {state.error.bool && state.error.message ? 
                                <span style={{ color: 'red', fontSize: '0.9rem' }}>{state.error.message}</span>
                            :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            );
        };
    };

    return <Navigate to='/' />;
};