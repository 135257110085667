import React, { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import PasswordStrength from '../PasswordStrength';
import GeneratorQRDisplay from './GeneratorQRDisplay';
import adminAPI from '../../API/adminAPI';
import styles from './SignupComponent.module.css';

const SignupComponent = (props) => {
    // ----- STATE INITIALIZATION -----
    const { loginRedirect, handleSignUp, password, setPassword, setUp, email, setEmail, isLoading, gen_id, state, setState } = props;

    const [ emailValid , setEmailValid ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ inputType, setInputType ] = useState('password');
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- AUTH FUNCTIONS -----
    
    const debouncedEmailFilter = useMemo(() => 
        debounce(async search => {
            await adminAPI.getUserByEmail(search)
                .then(res => {
                    if (!res.data.message) {
                        setEmailValid(false);
                        setMessage("unavailable");
                    } else {
                        setEmailValid(true);
                        setMessage('');
                    };
                })
                .catch(err => console.log(err));
        }, 500),
    []);

    useEffect(() => {
        const handleEmailCheck = () => {
            const emailRE = new RegExp(/[^@]+@[^@]+\.[^@]+/);
            if (email?.length > 0) {
                if (emailRE.test(email)) {
                    debouncedEmailFilter(email);
                } else {
                    setEmailValid(false);
                };
            } else {
                setEmailValid(false);
                setMessage('');
            };
        };

        handleEmailCheck();

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[email]);
    // ----- END AUTH FUNCTIONS -----
    // ----------------------------------------------

    useEffect(() => {
        const submitBtn = document.getElementById('submit');
        window.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                submitBtn?.click();
            };
        });
        return () => {window.removeEventListener('keypress', () => {})};
    },[]);

    return (
        <div className={styles.container} style={setUp === true ? {padding: '0 1rem'} :{}}>
            {setUp === true ? <></> : <h2 className='text-center mb-2'>Sign Up</h2>}
            {
            // Add query for if SetUp
            <div className={styles.existing}>
                Already a user? <span onClick={loginRedirect}>LOGIN</span>
            </div>
            }
            <div className={styles.inputGroup} style={{marginBottom: '1rem'}}>
                <input
                    type='email'
                    id='signup-email'
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    autoComplete="off"
                    required
                />
                <label htmlFor='signup-email' className={styles.label} style={message?.length > 0 ? {color: 'red'} : null}>Email</label>
                {message?.length > 0 ? <div className={styles.message}>{message}</div> : null}
                {email?.length > 0 ?
                    <div className={styles.icon}>
                        <i 
                            className={emailValid ? 'far fa-check-circle' : 'far fa-times-circle'} 
                            style={emailValid ? {color: 'green'} : {color: 'red'}}    
                        />
                    </div>
                    : null
                }
            </div>
            <div className={styles.inputGroup}>
                <input
                    type={inputType}
                    id='signup-password'
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    autoComplete="off"
                    required
                />
                <label htmlFor='signup-password' className={styles.label}>Password</label>
                <button className={styles.hidePassword} onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} disabled={password?.length < 1}>{inputType === 'password' ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}</button>
            </div>
            <PasswordStrength password={password} />
            {gen_id ?
                <GeneratorQRDisplay gen_id={gen_id} state={state} setState={setState} />
            :
                <></>
            }
            <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                <button type='submit' id='submit' className={styles.btn} onClick={handleSignUp} disabled={(emailValid === false) || password?.length < 1 || isLoading}>Submit</button>
            </div>
        </div>
    );
};

export default SignupComponent;