import API from '../../API';

export const handleAddQr = async ({ org_id, qrId, bsUid='', ent='', qrStyle }) => {
    if (!org_id || !qrId) throw('Missing data');

    const { createLiquidQR } = API;
    const date = new Date();
    
    const qrObj = {
        active: true,
        bitsignal: { uid: bsUid, authenticated: true },
        category: null,
        created_at: date,
        microsite: '',
        nickname: bsUid ? 'BitSignal' : 'BitSignal Enterprise QR',
        org_id: org_id,
        qr_type: bsUid ? 'bitsignal' : 'link',
        qr_style: qrStyle ? {
            ...qrStyle,
            height: 300,
            width: 300,
        } : {
            shape: 'square',
            width: 300,
            height: 300,
            margin: 0,
            qrOptions: {
                typeNumber: '0',
                mode: 'Byte',
                errorCorrectionLevel: 'M'
            },
            data: `https://lqr.ai/${qrId}`,
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
                crossOrigin: 'anonymous'
            },
            dotsOptions: {
                type: 'square',
                color: '#000000',
                gradient: null
            },
            backgroundOptions: {
                color: '#ffffff',
                gradient: null
            },
            image: null,
            cornersSquareOptions: {
                type: 'square',
                color: '#000000',
                gradient: null
            },
            cornersDotOptions: {
                type: 'square',
                color: '#000000',
                gradient: null
            }
        },
        schedule: null,
        redirect_url: ent ? `${process.env.REACT_APP_BS_ORIGIN}/${ent}` : '',
        tags: [],
        updated_at: date
    };
    
    try {
        await createLiquidQR(qrId, qrObj);
        return qrId;
    } catch (err) {
        console.error(err);
        throw(err);
    };
};